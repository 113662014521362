import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { toast } from "react-toastify";
import ListingItem from "../components/ListingItem";
import Spinner from "../components/Spinner";
import { BsSearch } from "react-icons/bs";

export default function Search() {
  const [listings, setListings] = useState([]);
  const { city, country, price, type } = useParams();
  const [loading, setLoading] = useState(true);
  const [city2, setCity2] = useState(city || "");
  const [country2, setCountry2] = useState(country || "");
  const [type2, setType2] = useState(type || "");
  const [price2, setPrice2] = useState(price || "");

  const fetchListings = async () => {
    try {
      setLoading(true);
      const listingsRef = collection(db, "listings");
      let allListings = [];

      // Get all listings first
      const q = query(listingsRef, orderBy("timestamp", "desc"));
      const querySnap = await getDocs(q);

      querySnap.forEach((doc) => {
        allListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      // Filter listings based on criteria
      let filteredListings = allListings;

      if (city) {
        filteredListings = filteredListings.filter(
          (listing) => listing.data.City.toLowerCase() === city.toLowerCase()
        );
      }

      if (country) {
        filteredListings = filteredListings.filter(
          (listing) =>
            listing.data.Country.toLowerCase() === country.toLowerCase()
        );
      }

      if (type) {
        filteredListings = filteredListings.filter(
          (listing) => listing.data.type.toLowerCase() === type.toLowerCase()
        );
      }

      if (price) {
        filteredListings = filteredListings.filter((listing) => {
          const listingPrice = listing.data.offer
            ? parseInt(listing.data.discountedPrice)
            : parseInt(listing.data.regularPrice);
          return listingPrice <= parseInt(price);
        });
      }

      console.log("Found listings:", filteredListings.length);
      setListings(filteredListings);
    } catch (error) {
      console.error("Error fetching listings:", error);
      toast.error("Could not fetch listings");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const fetchNewListings = async () => {
    try {
      setLoading(true);
      const listingsRef = collection(db, "listings");
      let allListings = [];

      // Get all listings first
      const q = query(listingsRef, orderBy("timestamp", "desc"));
      const querySnap = await getDocs(q);

      querySnap.forEach((doc) => {
        allListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      // Filter listings based on criteria
      let filteredListings = allListings;

      if (city2) {
        filteredListings = filteredListings.filter(
          (listing) => listing.data.City.toLowerCase() === city2.toLowerCase()
        );
      }

      if (country2) {
        filteredListings = filteredListings.filter(
          (listing) =>
            listing.data.Country.toLowerCase() === country2.toLowerCase()
        );
      }

      if (type2) {
        filteredListings = filteredListings.filter(
          (listing) => listing.data.type.toLowerCase() === type2.toLowerCase()
        );
      }

      if (price2) {
        filteredListings = filteredListings.filter((listing) => {
          const listingPrice = listing.data.offer
            ? parseInt(listing.data.discountedPrice)
            : parseInt(listing.data.regularPrice);
          return listingPrice <= parseInt(price2);
        });
      }

      console.log("Found new listings:", filteredListings.length);
      setListings(filteredListings);
    } catch (error) {
      console.error("Error fetching listings:", error);
      toast.error("Could not fetch listings");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-semibold mb-8">Search Properties</h2>

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <div className="grid grid-cols-1 tablet:grid-cols-5 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              City
            </label>
            <input
              type="text"
              value={city2}
              onChange={(e) => setCity2(e.target.value)}
              placeholder="e.g. Agadir"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Country
            </label>
            <input
              type="text"
              value={country2}
              onChange={(e) => setCountry2(e.target.value)}
              placeholder="e.g. Morocco"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Max Price
            </label>
            <input
              type="number"
              value={price2}
              onChange={(e) => setPrice2(e.target.value)}
              placeholder="Enter max price"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <select
              value={type2}
              onChange={(e) => setType2(e.target.value)}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">Select Type</option>
              <option value="rent">Rent</option>
              <option value="sale">Sale</option>
            </select>
          </div>

          <div className="flex items-end">
            <button
              onClick={fetchNewListings}
              className="w-full bg-primary text-white px-4 py-2 rounded-md hover:bg-dark transition-colors duration-300 flex items-center justify-center"
            >
              <BsSearch className="mr-2" />
              Search
            </button>
          </div>
        </div>
      </div>

      <div>
        {loading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : listings.length > 0 ? (
          <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4 gap-6">
            {listings.map((listing) => (
              <ListingItem
                key={listing.id}
                listing={listing.data}
                id={listing.id}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <p className="text-xl font-semibold text-gray-600">
              No properties found matching your criteria
            </p>
            <p className="text-gray-500 mt-2">
              Try adjusting your search filters
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
